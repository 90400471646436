import React, { CSSProperties, useState } from "react";
import {
  CommissionsUploadManagerClient,
  CommissionUploadManagerSearchResult,
  ICommissionUploadManagerSearchResult,
  UpdatedEnsSaleCarrierPaymentRequest,
  UpdateEnsSaleCarrierPaymentsByTidRequest,
  UpdateEnsSaleCarrierPaymentsByTidsRequest,
} from "common/services/microservices/finance-client";
import { Button, Modal, Input, Form, message } from "antd";
import { SubmitButton } from "common/components/SubmitButton";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";
import { SaveOutlined } from "@ant-design/icons";
import { LedgerCategoryDropdown } from "operations/common/LedgerCategoryDropdown";

interface EditCommissionRecordModalProps {
  record: CommissionUploadManagerSearchResult;
  updateMultipleRecords: boolean;
  recordKeysToUpdate: number[];
  visible: boolean;
  closeModal: () => void;
  refreshGrid: (
    updatedRecord: CommissionUploadManagerSearchResult | UpdatedEnsSaleCarrierPaymentRequest,
    updateSingleRecord?: boolean
  ) => void;
}

interface SaveButtonProps {
  style: CSSProperties;
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}

const SaveButton = (props: SaveButtonProps) => (
  <Button
    {...props}
    disabled={props.loading || props.disabled}
    type="primary"
    htmlType="button"
    onClick={props.onClick}
  >
    <SaveOutlined />
  </Button>
);

type Common<A, B> = {
  [P in keyof A & keyof B]: A[P] | B[P];
};

export default function EditCommissionRecordModal(props: EditCommissionRecordModalProps) {
  const { record, updateMultipleRecords, recordKeysToUpdate, visible, closeModal, refreshGrid } = props;
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [updateRecord, setUpdateRecord] = useState<ICommissionUploadManagerSearchResult>(record);

  function UpdateCarrierPayment() {
    if (updateRecord) {
      const updatedRecord = new UpdateEnsSaleCarrierPaymentsByTidRequest({
        payment_tid: updateRecord.ens_sale_carrier_payment_tid,
        update_record: new UpdatedEnsSaleCarrierPaymentRequest({ ...updateRecord }),
      });

      authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient)
        .then((client) => {
          setUpdateButtonLoading(true);
          return client.updateEnsSaleCarrierPayment(updatedRecord);
        })
        .then((response) => {
          setUpdateButtonLoading(false);
          message.success(`Successfully finished updated record.`);
          refreshGrid(updatedRecord, true);
          closeModal();
          return response;
        })
        .catch((e: string) => {
          message.error(`Failed to retrieve records` + e.toString());
          setUpdateButtonLoading(false);
        });
    }
  }

  type SearchAndUpdateCommonKeys = Common<UpdatedEnsSaleCarrierPaymentRequest, ICommissionUploadManagerSearchResult>;

  function UpdateCarrierPaymentsBasedOnTids(keyToUpdate: keyof SearchAndUpdateCommonKeys) {
    const updateRecordRequest = new UpdatedEnsSaleCarrierPaymentRequest({
      [keyToUpdate]: updateRecord[keyToUpdate],
    });

    authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient)
      .then((client) => {
        setUpdateButtonLoading(true);
        return client.updateEnsSaleCarrierPaymentsByTid(
          new UpdateEnsSaleCarrierPaymentsByTidsRequest({
            payment_tids: recordKeysToUpdate,
            update_record: updateRecordRequest,
          })
        );
      })
      .then((response) => {
        setUpdateButtonLoading(false);
        message.success(`Successfully finished updated record.`);
        refreshGrid(updateRecordRequest);
        closeModal();
        return response;
      })
      .catch((e: string) => {
        message.error(`Failed to retrieve records` + e.toString());
        setUpdateButtonLoading(false);
      });
  }

  const saveButtonStyles = { display: updateMultipleRecords ? "initial" : "none" };

  return (
    <>
      <Modal
        title={"Ens Sale Carrier Tid: " + record.ens_sale_carrier_payment_tid}
        visible={visible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[]}
        width={"40%"}
      >
        <Form id="edit-commission-form" onFinish={UpdateCarrierPayment}>
          <Form.Item label="Sale Tid" className="col" style={{ width: "100%" }}>
            <Input
              value={updateRecord?.ens_sale_tid}
              className="edit-um-sale-tid"
              onChange={(e) => setUpdateRecord({ ...updateRecord, ens_sale_tid: parseInt(e.target.value) })}
            />
            <SaveButton
              style={saveButtonStyles}
              onClick={() => UpdateCarrierPaymentsBasedOnTids("ens_sale_tid")}
              loading={updateButtonLoading}
              disabled={updateRecord.ens_sale_tid === record.ens_sale_tid}
            />
          </Form.Item>
          <Form.Item label="Ledger Category" className="col">
            <LedgerCategoryDropdown
              value={updateRecord.ledger_category}
              onValueClicked={(value) =>
                setUpdateRecord({
                  ...updateRecord,
                  ledger_category: value,
                })
              }
            />
            <SaveButton
              style={saveButtonStyles}
              onClick={() => UpdateCarrierPaymentsBasedOnTids("ledger_category")}
              loading={updateButtonLoading}
              disabled={updateRecord.ledger_category === record.ledger_category}
            />
          </Form.Item>
          <Form.Item label="Payment Date" className="col">
            <Input
              type="date"
              value={updateRecord?.payment_date?.toISOString().split("T")[0]}
              className="edit-um-payment-date"
              onChange={(e) =>
                setUpdateRecord({
                  ...updateRecord,
                  payment_date: new Date(e.target.value),
                })
              }
            />
            <SaveButton
              style={saveButtonStyles}
              onClick={() => UpdateCarrierPaymentsBasedOnTids("payment_date")}
              loading={updateButtonLoading}
              disabled={updateRecord.payment_date === record.payment_date}
            />
          </Form.Item>
          <Form.Item label="Payment Effective Date" className="col" style={{ width: "100%" }}>
            <Input
              type="date"
              value={updateRecord?.payment_effective_date?.toISOString().split("T")[0]}
              className="edit-um-payment-eff-date"
              onChange={(e) =>
                setUpdateRecord({
                  ...updateRecord,
                  payment_effective_date: new Date(e.target.value),
                })
              }
            />
            <SaveButton
              style={saveButtonStyles}
              onClick={() => UpdateCarrierPaymentsBasedOnTids("payment_effective_date")}
              loading={updateButtonLoading}
              disabled={updateRecord.payment_effective_date === record.payment_effective_date}
            />
          </Form.Item>
          <Form.Item label="Payment Amount" className="col" style={{ width: "100%" }}>
            <Input
              type="number"
              value={updateRecord?.payment_amount}
              className="edit-um-payment-amount"
              onChange={(e) => setUpdateRecord({ ...updateRecord, payment_amount: parseFloat(e.target.value) })}
            />
            <SaveButton
              style={saveButtonStyles}
              onClick={() => UpdateCarrierPaymentsBasedOnTids("payment_amount")}
              loading={updateButtonLoading}
              disabled={updateRecord.payment_amount === record.payment_amount}
            />
          </Form.Item>
          <Form.Item label="Policy Effective Date" className="col" style={{ width: "100%" }}>
            <Input
              type="date"
              value={updateRecord?.policy_effective_date?.toISOString().split("T")[0]}
              className="edit-um-policy-eff-date"
              onChange={(e) =>
                setUpdateRecord({
                  ...updateRecord,
                  policy_effective_date: new Date(e.target.value),
                })
              }
            />
            <SaveButton
              style={saveButtonStyles}
              onClick={() => UpdateCarrierPaymentsBasedOnTids("policy_effective_date")}
              loading={updateButtonLoading}
              disabled={updateRecord.policy_effective_date === record.policy_effective_date}
            />
          </Form.Item>
          <Form.Item label="Policy Number" className="col" style={{ width: "100%" }}>
            <Input
              value={updateRecord?.policy_number}
              className="edit-um-policy-number"
              onChange={(e) => setUpdateRecord({ ...updateRecord, policy_number: e.target.value })}
            />
            <SaveButton
              style={saveButtonStyles}
              onClick={() => UpdateCarrierPaymentsBasedOnTids("policy_number")}
              loading={updateButtonLoading}
              disabled={updateRecord.policy_number === record.policy_number}
            />
          </Form.Item>
          <SubmitButton
            style={{ width: "20%", display: updateMultipleRecords ? "none" : "initial" }}
            className="mt-3"
            text={"Submit"}
            loading={updateButtonLoading}
          />
        </Form>
      </Modal>
    </>
  );
}
