import { Row, Col, DatePicker } from "antd";
import React from "react";

interface DatePickersProps {
  filterDates: boolean;
  saleDateStart: moment.Moment | undefined;
  saleDateEnd: moment.Moment | undefined;
  saleEffectiveDateStart: moment.Moment | undefined;
  saleEffectiveDateEnd: moment.Moment | undefined;
  setSaleDateStart: (date: moment.Moment | undefined) => void;
  setSaleDateEnd: (date: moment.Moment | undefined) => void;
  setSaleEffectiveDateStart: (date: moment.Moment | undefined) => void;
  setSaleEffectiveDateEnd: (date: moment.Moment | undefined) => void;
}

export function SaleDatePickers(props: DatePickersProps) {
  const {
    filterDates,
    saleDateStart,
    saleDateEnd,
    saleEffectiveDateStart,
    saleEffectiveDateEnd,
    setSaleDateStart,
    setSaleDateEnd,
    setSaleEffectiveDateStart,
    setSaleEffectiveDateEnd,
  } = props;

  return (
    <Row align="middle" justify="center" style={{ paddingBottom: "20px" }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div style={{ marginBottom: "8px" }}>Sale Date Range</div>
            <Row gutter={8}>
              <Col span={12}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Start Date"
                  disabled={!filterDates}
                  value={saleDateStart}
                  onChange={(date) => setSaleDateStart(date || undefined)}
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="End Date"
                  disabled={!filterDates}
                  value={saleDateEnd}
                  onChange={(date) => setSaleDateEnd(date || undefined)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div style={{ marginBottom: "8px" }}>Sale Effective Date Range</div>
            <Row gutter={8}>
              <Col span={12}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Start Date"
                  disabled={!filterDates}
                  value={saleEffectiveDateStart}
                  onChange={(date) => setSaleEffectiveDateStart(date || undefined)}
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="End Date"
                  disabled={!filterDates}
                  value={saleEffectiveDateEnd}
                  onChange={(date) => setSaleEffectiveDateEnd(date || undefined)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
