import { message, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  CalculateFutureValueClient,
  ForecastRunDatesResponse,
  ForecastStatus,
  IForecastRunStatus,
} from "common/services/microservices/finance-client";
import { authenticatedClient } from "core/components/AuthProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";

type ForecastRunStatusWTable = {
  table: string;
} & IForecastRunStatus;

export function ForecastStatusTool() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ForecastRunDatesResponse>();

  const columns: ColumnsType<ForecastRunStatusWTable> = [
    {
      key: "table",
      title: "Table",
      defaultSortOrder: null as SortOrder,
      render: (_, r, i) => r.table,
    },
    {
      key: "status",
      title: "Status",
      render: (_, r, i) => {
        switch (r.status) {
          case ForecastStatus.NoData:
            return "No Table Data";
          case ForecastStatus.InProgress:
            return "Run In Progress";
          case ForecastStatus.Finished:
            return "Finished";
          default:
            return "Unknown";
        }
      },
    },
    {
      key: "date",
      title: "Last Run Date",
      defaultSortOrder: null as SortOrder,
      render: (_, r, i) => {
        if (r.finishDate) {
          return moment(r.finishDate).format("MM/DD/YYYY hh:mm A");
        }
        return "";
      },
    },
  ];

  async function load() {
    try {
      setLoading(!true);
      const client = await authenticatedClient(MicroservicesEndpoint.finance, CalculateFutureValueClient);
      const result = await client.getLatestForecastRunDates();
      setData(result);
    } catch (e) {
      message.error("Failed to load latest data.");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    load();
  }, []);

  if (!data || loading) {
    return <Spin />;
  }

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={[
        { table: "777", ...data.tripleSeven },
        { table: "777 5% Elevator", ...data.tripleSeven5Pct },
        { table: "UHAS", ...data.uhas },
        { table: "Discounted", ...data.discounted },
      ]}
    />
  );
}
