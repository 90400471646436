import React from "react";
import { Row, Col, Select, Divider } from "antd";
import {
  BookingReportCsvFormat,
  BookingReportSaleFilters,
  ISimpleSaleFilterRequest,
} from "common/services/microservices/finance-client";
import { useState, useEffect } from "react";
import { SaleDatePickers } from "./SaleDatePickers";

interface TrueUpReportRequestBuilderProps {
  onFiltersChange: (f: ISimpleSaleFilterRequest, ok: boolean) => void;
}

export function TrueUpReportRequestBuilder(props: TrueUpReportRequestBuilderProps) {
  const { onFiltersChange } = props;
  const [reportType, setReportType] = useState<BookingReportCsvFormat>(BookingReportCsvFormat.AdminBaseSeparate);
  const [saleDateStart, setSaleDateStart] = useState<moment.Moment | undefined>(undefined);
  const [saleDateEnd, setSaleDateEnd] = useState<moment.Moment | undefined>(undefined);
  const [saleEffectiveDateStart, setSaleEffectiveDateStart] = useState<moment.Moment | undefined>(undefined);
  const [saleEffectiveDateEnd, setSaleEffectiveDateEnd] = useState<moment.Moment | undefined>(undefined);

  useEffect(() => {
    const saleFilters = new BookingReportSaleFilters({
      sale_date_start: saleDateStart?.toDate(),
      sale_date_end: saleDateEnd?.toDate(),
      sale_effective_date_start: saleEffectiveDateStart?.toDate(),
      sale_effective_date_end: saleEffectiveDateEnd?.toDate(),
    });

    onFiltersChange(
      {
        report_type: reportType,
        filters: saleFilters,
      },
      true
    );
  }, [reportType, saleDateStart, saleDateEnd, saleEffectiveDateStart, saleEffectiveDateEnd]);

  return (
    <>
      <Row align="middle" justify="center" style={{ marginBottom: "20px" }}>
        <Col span={6}>
          <Col>Report Format</Col>
          <Select<number>
            style={{ width: "100%" }}
            value={reportType}
            options={[
              { value: BookingReportCsvFormat.AdminBaseSeparate, label: "Admin/Base Separate" },
              { value: BookingReportCsvFormat.AdminBaseTogether, label: "Admin/Base Together" },
            ]}
            onChange={(value) => setReportType(value)}
          />
        </Col>
      </Row>
      <SaleDatePickers
        filterDates={true}
        saleDateStart={saleDateStart}
        saleDateEnd={saleDateEnd}
        saleEffectiveDateStart={saleEffectiveDateStart}
        saleEffectiveDateEnd={saleEffectiveDateEnd}
        setSaleDateStart={setSaleDateStart}
        setSaleDateEnd={setSaleDateEnd}
        setSaleEffectiveDateStart={setSaleEffectiveDateStart}
        setSaleEffectiveDateEnd={setSaleEffectiveDateEnd}
      />
      <Divider />
    </>
  );
}
